body {
  font-family: Arial, sans-serif;
  background-image: url(./assests/images/take4.webp);
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background: rgba(255, 255, 255, 0.9); /* Add opacity to background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 20px; /* Ensure there is margin around the container */
}

h1, h2, h3 {
  text-align: center;
  color: #ff7f00;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.orange-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff7f00;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.orange-button:hover {
  background-color: #e66b00;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  width: 100%;
 
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #ccc;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}
